import React, { useState, useEffect } from "react";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Privacypolicy from "./Pages/Privacypolicy";

import CookiesPopup from "./component/CookiesPopup";
import { CookieConsentProvider } from "./CookieConsentContext";

import Cookiepolicy from "./Pages/Cookiepolicy";

import FreeCoursePage from "./Sections/FreeCoursePage";

import Landingportal from "./portal/Landingportal";
import CreateAcc from "./portal/CreateAcc/CreateAcc";
import CreateCourse from "./portal/CreateAcc/CreateCourse";

import Frenchquizlanding from "./Sections/Frenchquiz/Frenchquizlanding";
import Frenchquiz from "./Sections/Frenchquiz/Frenchquiz";
import Ourcourses from "./Sections/Ourcourses/Ourcourses";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 800);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="App">
      {loading ? (
        <div className="skeleton__container">
          <div className="h16"></div>
          <div className="Skeleton2"></div>
        </div>
      ) : (
        <>
          <CookieConsentProvider>
            <CookiesPopup />
            <Router>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>

                <Route exact path="/privacy">
                  <Privacypolicy />
                </Route>
                <Route exact path="/cookies">
                  <Cookiepolicy />
                </Route>

                <Route exact path="/freecourse">
                  <FreeCoursePage />
                </Route>
                <Route exact path="/portal">
                  <Landingportal />
                </Route>
                <Route exact path="/CreateAcc">
                  <CreateAcc />
                </Route>
                <Route exact path="/CreateCourse">
                  <CreateCourse />
                </Route>
                <Route
                  exact
                  path="/Evaluate_your_french_level_Le_Cercle_Francais"
                >
                  <Frenchquizlanding />
                </Route>
                <Route
                  exact
                  path="/Evaluate_your_french_level_Le_Cercle_Francais_Quizz"
                >
                  <Frenchquiz />
                </Route>
                <Route exact path="/Our_Courses_levels_Le_Cercle_Francais">
                  <Ourcourses />
                </Route>
              </Switch>
            </Router>
          </CookieConsentProvider>
        </>
      )}
    </div>
  );
}

export default App;
