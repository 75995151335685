import React from "react";
import Kids from "../img/Kids.png";
import logoblack from "../img/logoblack.png";
import Footer from "./Footer";
import { ArrowForward } from "@mui/icons-material";
import "./KidPage.css";
import Headerhome from "./HomeComponents/Headerhome";

function KidPage() {
  return (
    <div className="V1Coursecontent">
      <div className="V1Coursecontent__container1">
       <Headerhome />
        <div className="V1Coursecontent__container1__img">
          <img src={Kids} alt="" />
        </div>
        <div className="V1Coursecontent__container1__title">
          <p>Kid course</p>
        </div>
        <div className="V1Coursecontent__container1__subtitle">
          <p>French DELF PRIM</p>
        </div>
      </div>
      <div className="V1CourseContent__containersflex">
        <div className="V1Coursecontent__container2">
          <div className="V1Coursecontent__container2__paragraph">
            <p>
              Diplôme d'études en langue française (DELF) Prim is a French
              course designed specifically for children of ages 6-12 separated
              into three levels (A1.1, A1.2, A2) from the Common European
              Framework of Reference for Languages (CEFR). Introduce your kid to
              French with our courses tailored for young learners.{" "}
            </p>
          </div>
          <div className="V1Coursecontent__container2__title">
            <p>Admissions Requirements</p>
          </div>

          <div className="V1Coursecontent__container2__paragraph">
            <p>
              Please review admissions requirements before applying. To
              participate to this course, kid must be aged between 6 to 12 years
              old. Students should apply before the application deadline.
            </p>
          </div>
        </div>
        <div className="V1Coursecontent__container3">
          <div className="V1Coursecontent__container3__applicationdeadline">
            <div className="V1Coursecontent__container3__df">
              <div className="V1Coursecontent__container3__title">
                <p>APPLICATION DEADLINE</p>
              </div>

              <div className="V1Coursecontent__container3__applicationdeadline__line"></div>
            </div>

            <b>
              <p>Courses Offered</p>
            </b>
            <p>DELF PRIM A1.1 - A1.2 - A2</p>

            <div className="V1Coursecontent__Deadline">
              <p>Deadline: Mar 28, 2024 | 08:00 pm</p>
            </div>

            <div className="V1Coursecontent__arrowtxt">
              <div className="V1Coursecontent__arrow">
                <ArrowForward
                  style={{
                    fontSize: "20px",
                    marginLeft: "2px",
                    marginTop: "2px",
                  }}
                />
              </div>
              <div className="V1Coursecontent__txt">
                <b>
                  <p>Apply to DELF PRIM now</p>
                </b>
              </div>
            </div>
          </div>
          <div className="V1Coursecontent__container3__contact">
            <div className="V1Coursecontent__container3__df">
              <div className="V1Coursecontent__container3__contact__p">
                <p>CONTACT</p>
              </div>
              <div className="V1Coursecontent__container3__applicationdeadline__line2"></div>
            </div>
            <b>
              <p>Questions about the Program ?</p>
            </b>
            <div className="V1Coursecontent__arrowtxt">
              <div className="V1Coursecontent__arrow">
                <ArrowForward
                  style={{
                    fontSize: "20px",
                    marginLeft: "2px",
                    marginTop: "2px",
                  }}
                />
              </div>
              <div className="V1Coursecontent__txt">
                <b>
                  <p>Le Cercle Français Contact</p>
                </b>
              </div>
            </div>
          </div>
          <div className="V1Coursecontent__container3__teacher">
            <div className="V1Coursecontent__container3__teacher__line"></div>
            <div className="V1Coursecontent__container3__teacher__name">
              <b>
                <p> Bhavya Iyer</p>
              </b>
            </div>
            <div className="V1Coursecontent__container3__teacher__role">
              <p> Head teacher</p>
            </div>
            <div className="V1Coursecontent__container3__email">
              <b>
                <p>E-mail</p>
              </b>
            </div>
            <div className="V1Coursecontent__container3__emailaddress">
              <p> contact@lecerclefrancais.in</p>
            </div>
            <div className="V1Coursecontent__container3__phone">
              <b>
                <p>Phone </p>
              </b>
            </div>
            <div className="V1Coursecontent__container3__phonenumber">
              <p> 91 87880 97983</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default KidPage;
