import React from "react";
import "./Card.css";
import { ArrowForwardIos } from "@mui/icons-material";
import Badge from "./Badge";

function Card(props) {
  return (
    <div className="Card">
      <div className="Card_left">
        <img src={props.image} alt="" className="card_img" />
      </div>
      <div className="Card_right">
        <div className="Card_right_heading">
          <p>{props.title}</p>
        </div>

        <Badge label={props.badge} />

        <div className="Card__icon__access">
          <ArrowForwardIos />
        </div>
      </div>
    </div>
  );
}

export default Card;
