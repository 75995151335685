import React from "react";
import KidPage from "../KidPage";

function Ourcourses() {
  return (
    <div>
      <KidPage />
    </div>
  );
}

export default Ourcourses;
