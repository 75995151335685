import { getFunctions, httpsCallable } from "firebase/functions";

export const envoyerEmail = async (data) => {
  const functions = getFunctions();
  const sendEmailCallable = httpsCallable(functions, 'sendEmail');
  try {
    const result = await sendEmailCallable(data);
    return result.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
