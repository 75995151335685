import React, { useState } from "react";
import "./Home.css";
import Footer from "../Sections/Footer";

import ContactZone from "../Sections/HomeComponents/ContactZone";
import Headerhome from "../Sections/HomeComponents/Headerhome";
import Adbanner from "../Sections/HomeComponents/Adbanner";
import Homelanding from "../Sections/HomeComponents/Homelanding";
import Coursescard from "../Sections/HomeComponents/Coursescard";
import Tagline from "../Sections/HomeComponents/Tagline";
import Tagline2 from "../Sections/HomeComponents/Tagline2";
import Frenchtest from "../Sections/HomeComponents/Frenchtest";

function Home() {
  const [showAdBanner, setShowAdbanner] = useState(true);

  const handleCloseAdbanner = () => {
    setShowAdbanner(false);
  };
  return (
    <div className="Home">
      <div className="Home__content">
        {showAdBanner && <Adbanner onClose={handleCloseAdbanner} />}
        <Headerhome />
        <Homelanding />
        <Coursescard />
        <Tagline />
        <Tagline2 />
        <Frenchtest />
        <ContactZone />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
