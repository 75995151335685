import React from "react";
import "./Tagline.css";

function Tagline() {
  return (
    <div className="Tagline">
      <div className="Tagline__title">
        <p>Find the right French course.</p>
      </div>

      <div className="Tagline__subheading">
        <p>With more than 1 000 students taught, we know language learning.</p>
      </div>
    </div>
  );
}

export default Tagline;
