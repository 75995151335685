import React, { useState, useEffect } from "react";
import db from "../../firebase";
import { ref, onValue, push } from "firebase/database";
import CreateCourse from "./CreateCourse";

function Step1() {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courses, setCourses] = useState([]);
  const [courseInfo, setCourseInfo] = useState(null);

  // Fonction pour récupérer les cours depuis Firebase
  const fetchCourses = () => {
    const coursesRef = ref(db, "Course/");
    onValue(coursesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const courseList = Object.keys(data); // Récupérer les noms des cours
        setCourses(courseList);
      } else {
        setCourses([]);
      }
    });
  };

  // Fonction pour récupérer les informations du cours sélectionné
  const fetchCourseInfo = (courseName) => {
    const courseRef = ref(db, `Course/${courseName}`);
    onValue(courseRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCourseInfo(data);
      } else {
        setCourseInfo(null);
      }
    });
  };

  // Appeler fetchCourses lorsque le composant se monte
  useEffect(() => {
    fetchCourses();
  }, []);

  const handleCourseChange = (e) => {
    const selectedCourse = e.target.value;
    setSelectedCourse(selectedCourse);
    fetchCourseInfo(selectedCourse); // Récupérer les infos du cours sélectionné
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const contentRef = ref(db, "Panier");

    push(contentRef, {
      SELECTED_COURSE: selectedCourse,
    })
      .then(() => {
        console.log("Données sauvegardées avec succès !");
      })
      .catch((error) => {
        console.error("Erreur lors de la sauvegarde :", error);
      });
  };

  return (
    <div>
      <p>STEP 1 : SELECT A COURSE</p>
      <form onSubmit={handleSubmit}>
        <select value={selectedCourse} onChange={handleCourseChange}>
          <option value="" disabled>
            Select a course
          </option>
          {courses.map((course) => (
            <option key={course} value={course}>
              {course}
            </option>
          ))}
        </select>

        <button type="submit">SEND DATA</button>
      </form>

      <div>
        {courseInfo ? (
          <div>
            <h4>Information pour {selectedCourse} :</h4>
            <p>ID: {courseInfo.ID}</p>
            <p>Dates: {courseInfo.Dates}</p>
            <p>Price: {courseInfo.Price}</p>
          </div>
        ) : (
          selectedCourse && (
            <p>Aucune information disponible pour {selectedCourse}</p>
          )
        )}
      </div>
    </div>
  );
}

export default Step1;
