import React from "react";
import Card from "../../component/Card";
import "./Coursecard.css";
import piou from "../../img/piou.png"
import fr from "../../img/fr.png"
import foot from "../../img/foot.png"

function Coursescard() {
  return (
    <div className="Coursecard">
      <div className="Coursecard__txt">
        <p>Our French classes</p>
      </div>

      <div className="Coursecard_Cards">
        <Card 
        image={piou}
        title={<><span>Piou Piou</span><br /><span>3 - 5yo</span></>}
        badge="22 sit available"

        />
        <div className="Card__Spacebetween"></div>
        <Card 
         image={fr}
         title={<><span>Kids</span><br /><span>5 - 12yo</span></>}
         badge="4 sit available"
         />
        <div className="Card__Spacebetween"></div>
        <Card 
         image={foot}
         title={<><span>Youngs</span><br /><span>13 - 17yo</span></>}

         badge="11 sit available"
         />
      </div>
    </div>
  );
}

export default Coursescard;
