import React from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

function CreateAcc() {
  return (
    <div>
      <h1>...</h1>
      <Step1 />
    </div>
  );
}

export default CreateAcc;
