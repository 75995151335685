import React from "react";
import "./Landingportal.css";
import logoblack from "../img/logoblack.png";
import Footer from "../Sections/Footer";
import Profilepic from "../img/Profilepic.jpeg";
import Profilepic2 from "../img/Profilepic2.jpeg";
import Profilepic3 from "../img/Profilepic3.jpeg";
import Profilepic4 from "../img/Profilepic4.jpeg";
import Profilepic5 from "../img/Profilepic5.jpeg";
import Profilepic6 from "../img/Profilepic6.jpeg";

function Landingportal() {
  return (
    <div>
      <div className="Landingportal">
        <a href="/">
          <div className="V1Coursecontent__container1__logo">
            <img src={logoblack} alt="" />
          </div>
        </a>

        <div className="Landing__wrapper">
          <div className="Landingportal__title">
            <p>
              The #1 platform to <br />
              start learning <span>French</span>
            </p>
          </div>

          <div className="Landingportal__p">
            <p>
              Join our community to access ressources and online French class
            </p>
          </div>
          <div className="Landingportal__aligncta">
            <a href="/CreateAcc">
              <button className="V1CTA">Start 🇫🇷</button>
            </a>
          </div>
          <div className="Landingportal__p">
            <p>Our commubnity got already +2000 members</p>
          </div>
          <div className="Landingportal__memberspic">
            <div className="Landingportal__memberpic__img1">
              <img src={Profilepic} alt="" />
            </div>
            <div className="Landingportal__memberpic__img1">
              <img src={Profilepic2} alt="" />
            </div>
            <div className="Landingportal__memberpic__img1">
              <img src={Profilepic3} alt="" />
            </div>
            <div className="Landingportal__memberpic__img1">
              <img src={Profilepic4} alt="" />
            </div>
            <div className="Landingportal__memberpic__img1">
              <img src={Profilepic5} alt="" />
            </div>
            <div className="Landingportal__memberpic__img1">
              <img src={Profilepic6} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Landingportal;
