import React from "react";
import "./Tagline2.css";

function Tagline2() {
  return (
    <div className="Tagline2">
      <div className="Tagline2__title">
        <p>French classes your kid will love.
            <br />
        Guaranteed. </p>
      </div>
    </div>
  );
}

export default Tagline2;
