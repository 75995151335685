import React, { useState } from "react";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

function BurgerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setIsOpen(open);
  };

  const iconProps = {
    style: { color: "white", fontSize: "28px", marginTop: "4px" },
  };

  const drawerStyle = {
    width: "300px",
  };

  return (
    <div>
      <div className="">
        <div className="Burger__front">
          <IconButton
            onClick={toggleDrawer(!isOpen)}
            edge="start"
            aria-label="menu"
          >
            {isOpen ? (
              <CloseIcon {...iconProps} />
            ) : (
              <MenuIcon {...iconProps} />
            )}
          </IconButton>
        </div>
        <div className="Burger__Drawer">
          <Drawer
            anchor="left"
            open={isOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{ style: drawerStyle }}
          >
            <div
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                <ListItem button>
                  <a href="/">
                    <ListItemText primary="Home" />
                  </a>
                </ListItem>

                <ListItem button>
                  <a href="Our_Courses_levels_Le_Cercle_Francais">
                    <ListItemText primary="Kids course" />
                  </a>
                </ListItem>

                <ListItem button>
                  <a href="/">
                    <ListItemText primary="Careers" />
                  </a>
                </ListItem>
              </List>
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default BurgerMenu;
