import React, { useState, useEffect } from "react";
import db from "../../firebase"; // Assurez-vous que votre configuration Firebase est correcte
import { ref, set, onValue, remove } from "firebase/database";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CreateCourse() {
  const [courseName, setCourseName] = useState("");
  const [coursePrice, setCoursePrice] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [courseDescription, setCourseDescription] = useState("");
  const [savedCourses, setSavedCourses] = useState({});
  const courseID = new Date().getTime();

  // Fonction pour récupérer les cours sauvegardés depuis Firebase
  const fetchSavedCourses = () => {
    const coursesRef = ref(db, "Course/");
    onValue(coursesRef, (snapshot) => {
      const data = snapshot.val();
      setSavedCourses(data || {});
    });
  };

  const handleDelete = (courseName) => {
    const courseRef = ref(db, `Course/${courseName}`);
    remove(courseRef).then(() => {
      fetchSavedCourses();
    });
  };

  // Appeler fetchSavedCourses lorsque le composant se monte
  useEffect(() => {
    fetchSavedCourses();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const courseRef = ref(db, `Course/${courseName}`);
    set(courseRef, {
      StartDate: startDate.toISOString().split("T")[0],
      EndDate: endDate.toISOString().split("T")[0],
      Price: coursePrice,
      ID: courseID,
      Description: courseDescription,
    }).then(() => {
      setCourseName("");
      setStartDate(new Date());
      setEndDate(new Date());
      setCoursePrice("");
      setCourseDescription("");
      fetchSavedCourses();
    });
  };

  return (
    <div>
      <h1>ADMIN</h1>
      <h3>Créer un cours</h3>
      <form onSubmit={handleSubmit}>
        <p>Course name</p>
        <input
          type="text"
          placeholder="Nom du cours (ex: A1)"
          value={courseName}
          onChange={(e) => setCourseName(e.target.value)}
          required
        />
        <br />
        <p>Course dates</p>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Date de début"
          dateFormat="dd/MM/yyyy"
        />

        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="Date de fin"
          dateFormat="dd/MM/yyyy"
        />
        <br />
        <p>Course price</p>
        <input
          type="text"
          placeholder="le prix "
          value={coursePrice}
          onChange={(e) => setCoursePrice(e.target.value)}
          required
        />
        <br />
        <p>Course description</p>
        <input
          type="text"
          placeholder="description"
          value={courseDescription}
          onChange={(e) => setCourseDescription(e.target.value)}
          required
        />
        <br />
        <button type="submit">Sauvegarder</button>
      </form>

      <h4>Voici les cours déjà sauvegardés :</h4>
      <ul>
        {Object.entries(savedCourses).map(
          ([course, { StartDate, EndDate, Price, Description }]) => (
            <p key={course}>
              Course name : {course}
              <br />
              <strong>Date de début :</strong> {StartDate}
              <br />
              <strong>Date de fin :</strong> {EndDate}
              <br />
              <br />
              Price : {Price} INR
              <br />
              Description : {Description}
              <br />
              <button onClick={() => handleDelete(course)}>Supprimer</button>
            </p>
          )
        )}
      </ul>
    </div>
  );
}

export default CreateCourse;
