import React, { useEffect, useState } from "react";
import "./Frenchquiz.css";
import "../../App.css";
import questions from "./questions.json";

import CallToAction from "../../component/CallToAction";

import gz from "../../img/gz.png";
import Arrow from "../../img/Arrow.png";
import { envoyerEmail } from "../../functions/envoyerEmail"; // Importer la fonction d'envoi d'email

import { ArrowBack, ArrowForward } from "@mui/icons-material";

function FrenchQuiz() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [showScore, setShowScore] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [email, setEmail] = useState("");
  const [messageReponse, setMessageReponse] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 400);

    return () => clearTimeout(timer);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (responses[currentQuestionIndex]) {
      setSelectedOption(responses[currentQuestionIndex]);
    } else {
      setSelectedOption("");
    }
  }, [currentQuestionIndex, responses]);

  const handleChange = (value) => {
    if (selectedOption === value) {
      setSelectedOption("");
    } else {
      setSelectedOption(value);
    }
  };

  const handleNext = () => {
    setResponses({
      ...responses,
      [currentQuestionIndex]: selectedOption,
    });

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowScore(true);
      // Envoyer les résultats par email
      handleSendEmail();
    }
  };

  const handlePrevious = () => {
    setResponses({
      ...responses,
      [currentQuestionIndex]: selectedOption,
    });

    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const calculateScore = () => {
    let score = 0;
    questions.forEach((question, index) => {
      const userAnswer = responses[index];
      if (
        userAnswer &&
        userAnswer.toLowerCase() === question.answer.toLowerCase()
      ) {
        score++;
      }
    });
    return score;
  };

  const getLevel = (score) => {
    if (score === questions.length) return "C1";
    if (score >= questions.length * 0.8) return "B2";
    if (score >= questions.length * 0.6) return "B1";
    if (score >= questions.length * 0.4) return "A2";
    return "A1";
  };

  const score = showScore ? calculateScore() : 0;
  const level = getLevel(score);

  const handleSendEmail = async () => {
    if (!email) {
      setMessageReponse("");
      return;
    }

    try {
      const resultat = await envoyerEmail({ email, score, level }); // Appeler la fonction d'envoi
      setMessageReponse(resultat.message);
    } catch (erreur) {
      setMessageReponse(erreur.message);
    }
  };

  return (
    <div className="FrenchQuiz">
      {showScore ? (
        <div className="FrenchQuiz__result">
          <div className="FrenchQuiz__result__img">
            <img src={gz} alt="" className="" />
          </div>

          <div className="FrenchQuiz__result__container">
            <h2>Congratulations</h2>
            <div className="FrenchQuiz__result__container__tagline">
              <p>You have completed the French test 🇫🇷</p>
            </div>
          </div>
          <div className="FrenchQuiz__result__content">
            <div className="FrenchQuiz__result__content__value">
              <p>Enter your e-mail to get your results!</p>
            </div>
            <div className="FrenchQuiz__result__container__tagline__arrow">
              <img src={Arrow} alt="" />
            </div>

            <div className="FrenchQuiz__result__input">
              <div className="FrenchQuiz__result__input__txt__2">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="@email"
                />
              </div>
            </div>
            {messageReponse && <p>{messageReponse}</p>}
            <div className="h32"></div>
            <div className="FrenchQuiz__result__container__cta">
              <CallToAction
                bg="#ff6b00"
                label="See my results"
                onClick={handleSendEmail}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="Frenchquiz__questions__container">
          <div className="Frenchquiz__questionslayout">
            <div className="Frenchquiz__amount">
              <p>{currentQuestionIndex + 1} / 40</p>
            </div>

            <div className="Frenchquiz__question__content">
              <p>{questions[currentQuestionIndex].question}</p>
            </div>
            <div className="Frenchquiz__img">
              {loading ? (
                <div className="Frenchquiz_img_skeleton"></div>
              ) : (
                <img
                  src={questions[currentQuestionIndex].url}
                  alt="French test"
                  title="French level test"
                />
              )}{" "}
            </div>

            {questions[currentQuestionIndex].options.map((option, i) => (
              <div
                key={i}
                className={`Frenchquiz__option ${
                  selectedOption === option ? "selected" : ""
                }`}
                onClick={() => handleChange(option)}
              >
                {option}
              </div>
            ))}
          </div>
          <div className="Frenchquiz__bottomblock">
            {currentQuestionIndex > 0 && (
              <button className="Frenchquiz__button" onClick={handlePrevious}>
                <div className="Frenchquiz__button__icon__previous">
                  <ArrowBack />
                </div>
                <p>Back</p>
              </button>
            )}

            <button
              className={`Frenchquiz__button ${
                !selectedOption ? "disabled" : ""
              }`}
              onClick={handleNext}
              disabled={!selectedOption}
            >
              <p>
                {currentQuestionIndex < questions.length - 1
                  ? "Next"
                  : "Finish"}
              </p>
              <div className="Frenchquiz__button__icon__next">
                <ArrowForward />
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function Frenchquiz() {
  return (
    <div className="Frenchquiz">
      <FrenchQuiz />
    </div>
  );
}

export default Frenchquiz;
